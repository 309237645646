module.exports = new Promise(resolve => {
  
    const importmap = window.importmap || {};
    const imports = importmap.imports || {};
  
    const importMapURL = imports["transport-plan-frontend/remoteEntry.js"];
  
    const base = importmap.base || location.origin;
    const scriptPath = importMapURL;
  
    
    if(!scriptPath){
      if(Object.keys(imports).length  === 0)
      console.error('Error loading MFE transportplanfrontend,it looks like your remote asset.json file is empty or invalid')
     else
      console.error('Error loading MFE transportplanfrontend,please make sure to provide the correct importMapKey or overrideURL when calling setDynamicRemote')
      resolve({
        get: (req) => null,
        init: (arg) => null
      })
      return
    }
    else {
      const script = document.createElement('script')
      script.src = new URL(scriptPath,base).toString()
    
      // if micro-frontend remote is available, resolve with it
      script.onload = () => {
        resolve({
          get: (req) => window['transportplanfrontend'].get(req),
          init: (arg) => {
            try {
              return window['transportplanfrontend'].init(arg)
            } catch {
              console.error('Remote container already initialized')
            }
          }
        })
      }
  
      // If micro-frontend is unavailable, resolve with nothing
      script.onerror = () => {
        let msg = 'Remote container for @transportplanfrontend failed to load on path '+ scriptPath
        console.error(msg)
    
        resolve({
          get: (req) => null,
          init: (arg) => null
        })
      }
        document.head.appendChild(script)
    }
  
  });